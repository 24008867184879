<template>
  <div v-if="authenticated && !$apollo.loading" class="position-relative">
    <b-container v-if="$route.meta.fromAccount" class="p-2 bg-white">
      <router-link
        :to="{ name: 'account' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" fixed-width />
      </router-link>
    </b-container>
    <venues
      v-if="venues"
      :venues="venues"
      :display-menu="false"
      :advertiser-day="advertiserDay"
      view="discover"
    />
    <portal to="content">
      <transition name="swipe">
        <router-view />
      </transition>
    </portal>
  </div>
  <div
    v-else-if="$apollo.loading"
    class="h-100 d-flex align-items-center justify-content-center bg-page-dark"
  >
    <font-awesome-icon
      :icon="['fas', 'spinner']"
      spin
      size="2x"
      class="text-white"
    />
  </div>
  <div v-else class="h-100 d-flex align-items-center justify-content-center">
    <b-btn variant="primary" @click="login"> Connectez-vous </b-btn>
  </div>
</template>
<script>
import DISCOVER_LIKES from "@/graphql/auth/discoverLikes.gql"
import venueList from "@/mixins/VenueList"
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql"
export default {
  components: {},
  mixins: [venueList],
  data() {
    return {
      searching: false,
      search: ""
    }
  },
  apollo: {
    venues: {
      query: DISCOVER_LIKES,
      skip: true,
      client: "auth",
      update(data) {
        return data.discoverLikes.map(d => d.venue)
      },
      error(error) {
        this.$store.commit("authenticated", false)
      }
    },
    advertiserDay: {
      query: ADVERTISER_DAY
    }
  },
  watch: {
    authenticated: function (newValue, oldValue) {
      if (this.authenticated && newValue != oldValue) {
        this.fetch()
      }
    }
  },
  mounted() {
    if (this.authenticated) {
      this.fetch()
    }
  },
  methods: {
    login() {
      this.$login.login()
    },
    fetch() {
      this.$apollo.queries.venues.skip = false
      this.$apollo.queries.venues.refetch()
    }
  }
}
</script>
